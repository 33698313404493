define('ember-cli-preloader/services/preloader', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Object.extend({
    init: function init() {
      var els = _ember['default'].$('[data-name="preloader"]');

      if (els.length) {
        this.set('els', els);
        run.schedule('afterRender', this, 'addLoadedClass');
        run.schedule('afterRender', this, 'removePreloader');
      }
    },

    options: null,

    addLoadedClass: function addLoadedClass(className) {
      var loadedClass = this.get('options.loadedClass');

      if ('undefined' === typeof className && loadedClass === false) {
        return;
      } else {
        className = 'string' === typeof className ? className : loadedClass || '';
      }

      var div = this.get('els').filter('div');

      return div && div.addClass && div.addClass(className);
    },

    removePreloader: function removePreloader(delay) {
      var removeDelay = this.get('options.removeDelay');

      if ('undefined' === typeof delay && removeDelay === false) {
        return;
      } else {
        delay = isNaN(delay) ? removeDelay || 0 : delay;
      }

      run.later(this, function () {
        var els = this.get('els');
        if (els && els.remove) {
          this.get('els').remove();
          this.set('els', null);
        }
      }, delay);
    }

  });
});