define("ember-l10n/test-helpers", ["exports", "ember-l10n/services/l10n"], function (_exports, _l10n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    let tHelper = Ember.Helper.helper(([str], hash) => {
      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    let ptHelper = Ember.Helper.helper(([str
    /*, ctxt*/
    ], hash) => {
      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    let nHelper = Ember.Helper.helper(([strSingular, strPlural, count], hash) => {
      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count
      }, hash));
    });
    let pnHelper = Ember.Helper.helper(([strSingular, strPlural, count
    /*, ctxt*/
    ], hash) => {
      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count
      }, hash));
    });
    context.register('helper:t', tHelper);
    context.register('helper:n', nHelper);
    context.register('helper:pt', ptHelper);
    context.register('helper:pn', pnHelper);
  }
});