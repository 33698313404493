define("ember-l10n/helpers/t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper provides gettext singularization for message ids.
   * It takes singular message id as positional arguments. All
   * placeholders can be provided through named arguments.
   *
   * ```html
   * {{t 'Your current role: {{role}}' role=someBoundProperty}}
   * ```
   *
   * @namespace Helper
   * @class T
   * @extends Ember.Helper
   * @public
   */
  var _default = Ember.Helper.extend({
    l10n: Ember.inject.service(),

    compute([msgid], hash) {
      if (!msgid) {
        return msgid;
      }

      return Ember.get(this, 'l10n').t(msgid, hash);
    },

    _watchLocale: Ember.observer('l10n.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});