define('ember-attacher/components/attach-tooltip', ['exports', 'ember-attacher/components/attach-popover', 'ember-attacher/defaults'], function (exports, _attachPopover, _defaults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _attachPopover.default.extend({
    ariaRole: 'tooltip',

    class: Ember.computed({
      get() {
        return this.get('_config').tooltipClass || _defaults.default.tooltipClass;
      },

      set(_key, value) {
        const tooltipClass = this.get('_config').tooltipClass || _defaults.default.tooltipClass;

        return `${tooltipClass} ${value}`;
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      this._currentTarget.setAttribute('aria-describedby', this.id);
    },

    popperTargetChanged: Ember.observer('popperTarget', function () {
      const oldTarget = this._currentTarget;
      if (oldTarget) {
        oldTarget.removeAttribute('aria-describedby');
      }

      this._super(...arguments);

      this.get('popperTarget').setAttribute('aria-describedby', this.id);
    }),

    willDestroyElement() {
      this._super(...arguments);

      const target = this._currentTarget;
      if (target) {
        target.removeAttribute('aria-describedby');
      }
    }
  });
});