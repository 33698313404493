define('ember-cli-ifa/initializers/asset-map', ['exports', 'rsvp', 'jquery', 'ember-cli-ifa/services/asset-map'], function (exports, _rsvp, _jquery, _emberCliIfaServicesAssetMap) {
  exports.initialize = initialize;

  function initialize(app) {
    var container = app.__container__;
    var config = undefined;
    if (container.factoryFor) {
      config = container.factoryFor('config:environment')['class'];
    } else {
      config = container.lookupFactory('config:environment');
    }

    var assetMapFile = window && window.__assetMapPlaceholder__;

    if (!assetMapFile) {
      var ifaPlaceholder = document.querySelector('[property="ifa:placeholder"]');

      if (ifaPlaceholder) {
        assetMapFile = decodeURIComponent(ifaPlaceholder.getAttribute('content'));
      }
    }

    if (!assetMapFile) {
      app.register('service:asset-map', _emberCliIfaServicesAssetMap['default']);
      return;
    }

    if (config.ifa.inline) {
      _emberCliIfaServicesAssetMap['default'].reopen({
        map: assetMapFile.assets,
        prepend: assetMapFile.prepend,
        enabled: true
      });
      app.register('service:asset-map', _emberCliIfaServicesAssetMap['default']);
    } else {
      app.deferReadiness();

      var promise = new _rsvp['default'].Promise(function (resolve, reject) {
        _jquery['default'].getJSON(assetMapFile, resolve).fail(reject);
      });

      promise.then(function () {
        var map = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        _emberCliIfaServicesAssetMap['default'].reopen({
          map: map.assets,
          prepend: map.prepend,
          enabled: true
        });
      }).then(function () {
        app.register('service:asset-map', _emberCliIfaServicesAssetMap['default']);
        app.advanceReadiness();
      });
    }
  }

  exports['default'] = {
    name: 'asset-map',
    initialize: initialize
  };
});
/* global window, __assetMapPlaceholder__ */