define("@zestia/ember-auto-focus/components/auto-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AutoFocusComponent = Ember.Component.extend({
    tagName: 'span',

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, '_autofocus');
    },

    _autofocus() {
      if (this.disabled) {
        return false;
      }

      const selector = this.selector || ':first-child';
      const child = this.element.querySelector(selector);

      if (child) {
        child.focus();
      }
    }

  });
  AutoFocusComponent.reopenClass({
    positionalParams: ['selector']
  });
  var _default = AutoFocusComponent;
  _exports.default = _default;
});