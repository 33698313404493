define("ember-api-actions/utils/serialize-and-push", ["exports", "ember-api-actions/utils/build-url"], function (_exports, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeAndPush;

  function isJsonApi(raw) {
    return raw.jsonapi && raw.jsonapi.version;
  }

  function isDocWithData(doc) {
    return isJsonApi(doc) && ['object', 'array'].indexOf(Ember.typeOf(doc.data)) >= 0;
  }

  function serializeAndPush(response) {
    if (!isDocWithData(response)) {
      // tslint:disable-next-line:no-console
      console.warn('serializeAndPush may only be used with a JSON API document. Ignoring response. ' + 'Document must have a mandatory JSON API object. See https://jsonapi.org/format/#document-jsonapi-object.');
      return response;
    }

    const recordClass = (0, _buildUrl._getModelClass)(this);
    const modelName = (0, _buildUrl._getModelName)(recordClass);
    const store = (0, _buildUrl._getStoreFromRecord)(this);
    const serializer = store.serializerFor(modelName);
    let normalized;

    if (Ember.isArray(response.data)) {
      const doc = response;
      normalized = serializer.normalizeArrayResponse(store, recordClass, doc, null, 'findAll');
    } else {
      const doc = response;
      normalized = serializer.normalizeSingleResponse(store, recordClass, doc, `${doc.data.id || '(unknown)'}`, 'findRecord');
    }

    return store.push(normalized);
  }
});