define('ember-maskmoney/components/imput-money', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({
    prefix: '',
    suffix: '',
    affixesStay: false,
    thousands: ',',
    decimal: '.',
    precision: 2,
    allowZero: true,
    allowNegative: false,
    allowDecimal: true,

    options: _ember['default'].computed('prefix', 'suffix', 'affixesStay', 'thousands', 'decimal', 'precision', 'allowZero', 'allowNegative', 'allowDecimal', function () {
      return {
        prefix: this.get('prefix'),
        suffix: this.get('suffix'),
        affixesStay: this.get('affixesStay'),
        thousands: this.get('thousands'),
        decimal: this.get('decimal'),
        precision: this.get('precision'),
        allowZero: this.get('allowZero'),
        allowNegative: this.get('allowNegative'),
        allowDecimal: this.get('allowDecimal')
      };
    }),

    initializeMask: _ember['default'].on('didInsertElement', function () {
      var self = this;
      _ember['default'].run.once(function () {
        self.$().maskMoney(self.get('options'));
        if (self.get('allowZero') && self.get('number') !== undefined || self.get('number')) {
          self.propertyDidChange('number');
        }
      });
    }),

    teardownMask: _ember['default'].on('willDestroyElement', function () {
      this.$().maskMoney('destroy');
    }),

    setMask: _ember['default'].observer('options', function () {
      this.$().maskMoney('destroy');
      this.$().maskMoney(this.get('options'));
    }),

    setMaskedValue: _ember['default'].observer('number', 'precision', 'decimal', function () {
      var number = parseFloat(this.get('number') || 0).toFixed(this.get('precision'));
      var val = number.toString().replace('.', this.get('decimal'));
      this.$().val(val);
      this.$().maskMoney('mask');
    }),

    setUnmaskedValue: _ember['default'].observer('value', 'allowDecimal', function () {
      if (this.get('allowDecimal')) {
        this.set('number', this.$().maskMoney('unmasked')[0]);
      } else {
        this.set('number', this.get('value').replace(/[^0-9]/g, ''));
      }
    })
  });
});