define("ember-l10n/helpers/pt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper provides contextual singular message, where context has to
   * be given as 4th argument, otherwise just works the same as `t` helper.
   *
   * ```html
   * {{pt 'This is {{variable}}.' variable=someBoundProperty 'context'}}
   * ```
   *
   * @namespace Helper
   * @class PT
   * @extends Ember.Helper
   * @public
   */
  var _default = Ember.Helper.extend({
    l10n: Ember.inject.service(),

    compute([msgid, msgctxt], hash) {
      let l10n = Ember.get(this, 'l10n');

      if (!msgid) {
        return msgid;
      }

      return l10n.pt(msgid, msgctxt, hash);
    },

    _watchLocale: Ember.observer('l10n.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});