define("ember-l10n/helpers/pn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper provides contextual plural message, where context has to
   * be given as 4th argument, otherwise just works the same as `n` helper.
   *
   * ```html
   * {{pn '{{count}} apple' '{{count}} apples' someBoundProperty 'context'}}
   * ```
   *
   * @namespace Helper
   * @class PN
   * @extends Ember.Helper
   * @public
   */
  var _default = Ember.Helper.extend({
    l10n: Ember.inject.service(),

    compute([msgid, msgidPlural, count, msgctxt], hash) {
      let l10n = Ember.get(this, 'l10n');

      if (!msgid) {
        return msgid;
      }

      return l10n.pn(msgid, msgidPlural, count, msgctxt, hash);
    },

    _watchLocale: Ember.observer('l10n.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});