define('ember-pollboy/services/pollboy', ['exports', 'ember', 'ember-pollboy/classes/poller'], function (exports, _ember, _emberPollboyClassesPoller) {
  var Service = _ember['default'].Service;

  /**
   * @callback {Function} PollboyCallback
   * @returns {Ember.RSVP.Promise} Resolved when next polling interval should begin
   */

  exports['default'] = Service.extend({
    pollers: [],

    init: function init() {
      this._super();
      this._onVisibilityChangeHandler = this.onVisibilityChange.bind(this);
      if (typeof document !== 'undefined') {
        document.addEventListener('visibilitychange', this._onVisibilityChangeHandler, false);
      }
    },

    willDestroy: function willDestroy() {
      if (typeof document !== 'undefined') {
        document.removeEventListener('visibilitychange', this._onVisibilityChangeHandler);
      }
    },

    /**
     * Pause/resume polling based on whether or not page is currently visible to user.
     * When user switches browser tabs polling will stop.
     * When user switches applications polling will stop.
     * When page comes back into focus any pollers that were stopped due to loss of page focus will resume.
     * @param {Event} event - visibilitychange event data
     */
    onVisibilityChange: function onVisibilityChange(event) {
      var pollers = this.get('pollers');

      // If page is no longer visible pause pollers
      if (event.target.hidden) {
        pollers.forEach(function (poller) {
          poller.pause();
        });

        return;
      }

      // If page has become visible make sure to resume pollers that were previously paused
      pollers.forEach(function (poller) {
        poller.resume();
      });
    },

    /**
     * Add new polling item
     * @param {Object} context - context to be provided to callback
     * @param {PollboyCallback} callback - callback function for each poll interval
     * @param {Number} interval - number of milliseconds between polls
     * @returns {Poller} poller instance
     */
    add: function add(context, callback, interval) {
      var poller = _emberPollboyClassesPoller['default'].create({
        callback: callback,
        context: context,
        interval: interval
      });

      poller.start();

      this.get('pollers').push(poller);

      return poller;
    },

    /**
     * Remove poller
     * @param {Poller} poller - Poller to remove
     */
    remove: function remove(poller) {
      poller.stop(); // Make sure poller is no longer polling

      var pollers = this.get('pollers');
      var index = pollers.indexOf(poller);

      if (index !== -1) {
        pollers.splice(index, 1);
      }
    }
  });
});