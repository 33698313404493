define("ember-cli-analytics/integrations/optimizely", ["exports", "ember-cli-analytics/integrations/base", "ember-cli-analytics/utils/can-use-dom", "ember-cli-analytics/utils/without"], function (_exports, _base, _canUseDom, _without) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackPage(options = {}) {
      const {
        experiment
      } = options;

      if (_canUseDom.default) {
        if (Ember.isPresent(experiment)) {
          window.optimizely.push(['activate', experiment]);
        } else {
          window.optimizely.push(['activate']);
        }
      }
    },

    /*
     * Send an arbitrary event to the
     * anlytics engine.
     *
     * @method trackEvent
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackEvent(options = {}) {
      const {
        event
      } = options;
      const properties = (0, _without.default)(options, 'event');
      (true && !(event) && Ember.assert('You must pass an event name', event));

      if (_canUseDom.default) {
        if (Ember.isPresent(Object.keys(properties))) {
          window.optimizely.push(['trackEvent', event, properties]);
        } else {
          window.optimizely.push(['trackEvent', event]);
        }
      }
    },

    /*
     * Insert the JavaScript tag into the
     * page, and perform any necessary
     * setup.
     *
     * @method insertTag
     * @on init
     */
    insertTag: Ember.on('init', function () {
      const config = Ember.get(this, 'config');
      const {
        id
      } = Ember.assign({}, config);
      (true && !(id) && Ember.assert('You must pass a valid `id` to the Optimizely adapter', id));

      if (_canUseDom.default && !window.optimizely) {
        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i['OptimizrlyObject'] = r;
          i[r] = i[r] || [];
          a = s.createElement(o), m = s.getElementsByTagName(o)[0];
          a.async = 0;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(window, document, 'script', `//cdn.optimizely.com/js/${id}.js`, 'optimizely');
        /* eslint-enable */

      }
    }),

    /*
     * Remove the JavaScript tag from the
     * page, and perform any necessary
     * teardown.
     *
     * @method removeTag
     * @on willDestroy
     */
    removeTag: Ember.on('willDestroy', function () {
      if (_canUseDom.default) {
        Ember.$(`script[src*="optimizely"]`).remove();
        delete window.ga;
      }
    })
  });

  _exports.default = _default;
});