define('ember-data-model-fragments/ext', ['exports', 'ember-data/store', 'ember-data/model', 'ember-data/-private', 'ember-data/serializers/json', 'ember-data-model-fragments/states', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/array/fragment'], function (exports, _store, _model, _private, _json, _states, _fragment, _fragment2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.JSONSerializer = exports.Model = exports.Store = undefined;


  function serializerForFragment(owner, normalizedModelName) {
    let serializer = owner.lookup(`serializer:${normalizedModelName}`);

    if (serializer !== undefined) {
      return serializer;
    }

    // no serializer found for the specific model, fallback and check for application serializer
    serializer = owner.lookup('serializer:-fragment');
    if (serializer !== undefined) {
      return serializer;
    }

    // final fallback, no model specific serializer, no application serializer, no
    // `serializer` property on store: use json-api serializer
    serializer = owner.lookup('serializer:-default');

    return serializer;
  }
  /**
    @module ember-data-model-fragments
  */

  let InternalModelPrototype = _private.InternalModel.prototype;

  /**
    @class Store
    @namespace DS
  */
  _store.default.reopen({
    /**
      Create a new fragment that does not yet have an owner record.
      The properties passed to this method are set on the newly created
      fragment.
       To create a new instance of the `name` fragment:
       ```js
      store.createFragment('name', {
        first: 'Alex',
        last: 'Routé'
      });
      ```
       @method createRecord
      @param {String} type
      @param {Object} properties a hash of properties to set on the
        newly created fragment.
      @return {MF.Fragment} fragment
    */
    createFragment(modelName, props) {
      (true && !(this.isFragment(modelName)) && Ember.assert(`The '${modelName}' model must be a subclass of MF.Fragment`, this.isFragment(modelName)));


      let internalModel = new _private.InternalModel(modelName, null, this, (0, _private.getOwner)(this).container);

      // Re-wire the internal model to use the fragment state machine
      internalModel.currentState = _states.default.empty;

      internalModel._name = null;
      internalModel._owner = null;

      internalModel.loadedData();

      let fragment = internalModel.getRecord();

      if (props) {
        fragment.setProperties(props);
      }

      // invoke the ready callback ( to mimic DS.Model behaviour )
      fragment.trigger('ready');

      // Add brand to reduce usages of `instanceof`
      fragment._isFragment = true;

      return fragment;
    },

    /**
      Returns true if the modelName is a fragment, false if not
       @method isFragment
      @private
      @param {String} the modelName to check if a fragment
      @return {boolean}
    */
    isFragment(modelName) {
      if (modelName === 'application' || modelName === '-default') {
        return false;
      }

      let type = this.modelFor(modelName);
      return _fragment.default.detect(type);
    },

    serializerFor(modelName) {
      (true && !(Ember.isPresent(modelName)) && Ember.assert('You need to pass a model name to the store\'s serializerFor method', Ember.isPresent(modelName)));
      (true && !(typeof modelName === 'string') && Ember.assert(`Passing classes to store.serializerFor has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));


      let owner = (0, _private.getOwner)(this);
      let normalizedModelName = (0, _private.normalizeModelName)(modelName);

      if (this.isFragment(normalizedModelName)) {
        return serializerForFragment(owner, normalizedModelName);
      } else {
        return this._super(...arguments);
      }
    }
  });

  /**
    @class Model
    @namespace DS
    */
  _model.default.reopen({
    /**
      Returns an object, whose keys are changed properties, and value is
      an [oldProp, newProp] array. When the model has fragments that have
      changed, the property value is simply `true`.
       Example
       ```javascript
      App.Mascot = DS.Model.extend({
        type: DS.attr('string'),
        name: MF.fragment('name')
      });
       App.Name = DS.Model.extend({
        first : DS.attr('string'),
        last  : DS.attr('string')
      });
       let person = store.createRecord('person');
      person.changedAttributes(); // {}
      person.get('name').set('first', 'Tomster');
      person.set('type', 'Hamster');
      person.changedAttributes(); // { name: true, type: [undefined, 'Hamster'] }
      ```
       @method changedAttributes
      @return {Object} an object, whose keys are changed properties,
        and value is an [oldProp, newProp] array.
    */
    changedAttributes() {
      let diffData = this._super(...arguments);
      let internalModel = (0, _fragment.internalModelFor)(this);

      Object.keys(internalModel._fragments).forEach(name => {
        // An actual diff of the fragment or fragment array is outside the scope
        // of this method, so just indicate that there is a change instead
        if (name in internalModel._attributes) {
          diffData[name] = true;
        }
      });

      return diffData;
    },

    willDestroy() {
      this._super(...arguments);

      let internalModel = (0, _fragment.internalModelFor)(this);
      let key, fragment;

      // destroy the current state
      for (key in internalModel._fragments) {
        fragment = internalModel._fragments[key];
        if (fragment) {
          fragment.destroy();
          delete internalModel._fragments[key];
        }
      }

      // destroy the original state
      for (key in internalModel._data) {
        fragment = internalModel._data[key];
        if (fragment instanceof _fragment.default || fragment instanceof _fragment2.default) {
          fragment.destroy();
          delete internalModel._data[key];
        }
      }
    }
  });

  _model.default.reopenClass({
    fields: Ember.computed(function () {
      let map = new Map();

      this.eachComputedProperty((name, meta) => {
        if (meta.isFragment) {
          map.set(name, 'fragment');
        } else if (meta.isRelationship) {
          map.set(name, meta.kind);
        } else if (meta.isAttribute) {
          map.set(name, 'attribute');
        }
      });

      return map;
    }).readOnly()

  });

  // Replace a method on an object with a new one that calls the original and then
  // invokes a function with the result
  function decorateMethod(obj, name, fn) {
    let originalFn = obj[name];

    obj[name] = function () {
      let value = originalFn.apply(this, arguments);

      return fn.call(this, value, arguments);
    };
  }

  /**
    Override parent method to snapshot fragment attributes before they are
    passed to the `DS.Model#serialize`.
  
    @method _createSnapshot
    @private
  */
  decorateMethod(InternalModelPrototype, 'createSnapshot', function createFragmentSnapshot(snapshot) {
    let attrs = snapshot._attributes;

    Object.keys(attrs).forEach(key => {
      let attr = attrs[key];

      // If the attribute has a `_createSnapshot` method, invoke it before the
      // snapshot gets passed to the serializer
      if (attr && typeof attr._createSnapshot === 'function') {
        attrs[key] = attr._createSnapshot();
      }
    });

    return snapshot;
  });

  /**
    If the model `hasDirtyAttributes` this function will discard any unsaved
    changes, recursively doing the same for all fragment properties.
  
    Example
  
    ```javascript
    record.get('name'); // 'Untitled Document'
    record.set('name', 'Doc 1');
    record.get('name'); // 'Doc 1'
    record.rollbackAttributes();
    record.get('name'); // 'Untitled Document'
    ```
  
    @method rollbackAttributes
  */
  decorateMethod(InternalModelPrototype, 'rollbackAttributes', function rollbackFragments() {
    for (let key in this._fragments) {
      if (this._fragments[key]) {
        this._fragments[key].rollbackAttributes();
      }
    }
  });

  /**
    Before saving a record, its attributes must be moved to in-flight, which must
    happen for all fragments as well
  
    @method flushChangedAttributes
  */
  decorateMethod(InternalModelPrototype, 'flushChangedAttributes', function flushChangedAttributesFragments() {
    let fragment;

    // Notify fragments that the record was committed
    for (let key in this._fragments) {
      fragment = this._fragments[key];
      if (fragment) {
        fragment._flushChangedAttributes();
      }
    }
  });

  /**
    If the adapter did not return a hash in response to a commit,
    merge the changed attributes and relationships into the existing
    saved data and notify all fragments of the commit.
  
    @method adapterDidCommit
  */
  decorateMethod(InternalModelPrototype, 'adapterDidCommit', function adapterDidCommitFragments(returnValue, args) {
    let attributes = args[0] && args[0].attributes || Object.create(null);
    let fragment;

    // Notify fragments that the record was committed
    for (let key in this._fragments) {
      fragment = this._fragments[key];
      if (fragment) {
        fragment._adapterDidCommit(attributes[key]);
      }
    }
  });

  decorateMethod(InternalModelPrototype, 'adapterDidError', function adapterDidErrorFragments(returnValue, args) {
    let error = args[0] || Object.create(null);
    let fragment;

    // Notify fragments that the record was committed
    for (let key in this._fragments) {
      fragment = this._fragments[key];
      if (fragment) {
        fragment._adapterDidError(error);
      }
    }
  });

  /**
    @class JSONSerializer
    @namespace DS
  */
  _json.default.reopen({
    /**
      Enables fragment properties to have custom transforms based on the fragment
      type, so that deserialization does not have to happen on the fly
       @method transformFor
      @private
    */
    transformFor(attributeType) {
      if (attributeType.indexOf('-mf-') === 0) {
        return getFragmentTransform((0, _private.getOwner)(this), this.store, attributeType);
      }

      return this._super(...arguments);
    }
  });

  // Retrieve or create a transform for the specific fragment type
  function getFragmentTransform(owner, store, attributeType) {
    let containerKey = `transform:${attributeType}`;
    let match = attributeType.match(/^-mf-(fragment|fragment-array|array)(?:\$([^$]+))?(?:\$(.+))?$/);
    let transformName = match[1];
    let transformType = match[2];
    let polymorphicTypeProp = match[3];

    if (!owner.hasRegistration(containerKey)) {
      let transformClass = owner.factoryFor(`transform:${transformName}`);
      transformClass = transformClass && transformClass.class;

      owner.register(containerKey, transformClass.extend({
        store: store,
        type: transformType,
        polymorphicTypeProp: polymorphicTypeProp
      }));
    }

    return owner.lookup(containerKey);
  }

  exports.Store = _store.default;
  exports.Model = _model.default;
  exports.JSONSerializer = _json.default;
});