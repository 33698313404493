define('ember-pollboy/classes/poller', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Object.extend({
    /**
     * Used to determine if poller is currently paused (i.e. when tab is not visible)
     * @type {Boolean}
     */
    isPaused: false,
    /**
     * Used to determine if poller is currently stopped
     * @type {Boolean}
     */
    isStopped: false,
    /**
     * Schedule next poll interval
     * @returns {*} Timer information for use in cancelling, see `Ember.run.cancel`.
     */
    schedule: function schedule() {
      // Don't schedule another poll if we're not polling
      if (!this.get('isPaused') && !this.get('isStopped')) {
        var interval = this.get('interval');

        return run.later(this, this.poll, interval);
      }
    },

    /**
     * Cancel current polling interval
     */
    cancel: function cancel() {
      var timer = this.get('timer');
      run.cancel(timer);
    },

    /**
     * Pause polling
     */
    pause: function pause() {
      this.set('isPaused', true);
      this.cancel();
    },

    /**
     * Poll immediately
     */
    poll: function poll() {
      var _this = this;

      var callback = this.get('callback');
      var context = this.get('context');

      this.cancel();

      callback.apply(context).then(function () {
        _this.set('timer', _this.schedule());
      });
    },

    /**
     * Resume polling if paused
     */
    resume: function resume() {
      var isPaused = this.get('isPaused');

      if (isPaused) {
        this.poll();
        this.set('isPaused', false);
      }
    },

    /**
     * Begin polling
     */
    start: function start() {
      this.cancel(); // Make sure no previous polling interval
      this.set('isStopped', false);
      var timer = this.schedule();
      this.set('timer', timer);
    },

    /**
     * Stop polling
     */
    stop: function stop() {
      this.set('isStopped', true);
      this.cancel();
    }
  });
});