define("ember-cli-analytics/services/analytics", ["exports", "ember-cli-adapter-pattern/mixins/adaptable", "ember-cli-adapter-pattern/utils/proxy-to-adapter"], function (_exports, _adaptable, _proxyToAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_adaptable.default, {
    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     */
    trackPage: (0, _proxyToAdapter.default)('trackPage'),

    /*
     * Send an arbitrary event to the
     * anlytics engine.
     *
     * @method trackEvent
     */
    trackEvent: (0, _proxyToAdapter.default)('trackEvent'),

    /*
     * Send a conversion completion
     * event to the analytics engine.
     *
     * @method trackConversion
     */
    trackConversion: (0, _proxyToAdapter.default)('trackConversion'),

    /*
     * Identify an anonymous user with a
     * unique ID. This is useful when a
     * user returns to the application
     * an we wish to further track them.
     *
     * This should not be called in
     * conjunction with alias.
     *
     * @method identity
     */
    identify: (0, _proxyToAdapter.default)('identify'),

    /*
     * For those platforms that support
     * it, map an anonymous user id to a
     * registered user. This is useful
     * when you wish to associate events
     * made before the user registerd
     * with a newly created user account.
     *
     * @method identify
     */
    alias: (0, _proxyToAdapter.default)('alias'),

    /*
     * Fetch the adapter configuation and
     * ensure that we have a clean cache
     * of adapters.
     *
     * Further to this we register our
     * adapter classes with the option
     * to not instatiate them immediately.
     *
     * @method createAdapters
     * @on init
     */
    createAdapters: Ember.on('init', function () {
      const adapters = Ember.getWithDefault(this, 'config.analytics.integrations', Ember.A());
      const owner = Ember.getOwner(this); // Integrations should not be instantiated.

      owner.registerOptionsForType('ember-cli-analytics@integration', {
        instantiate: false
      });
      owner.registerOptionsForType('integration', {
        instantiate: false
      });
      Ember.set(this, '_adapters', {});
      Ember.set(this, 'context', {});
      this.activateAdapters(adapters);
    }),

    /*
     * Lookup adapters from the application
     * container.
     *
     * @method lookupAdapter
     *
     * @param {String} adapterName
     *   Name of the adapter.
     *
     * @return {Object}
     *   Uninstantiated adapter object.
     */
    _lookupAdapter(adapterName) {
      (true && !(adapterName) && Ember.assert('Could not find integration without a name', adapterName));
      const owner = Ember.getOwner(this);
      const dasherizedAdapterName = Ember.String.dasherize(adapterName);
      const localAdapter = owner.lookup(`ember-cli-analytics@integration:${dasherizedAdapterName}`);
      const adapter = owner.lookup(`integration:${dasherizedAdapterName}`);
      return adapter ? adapter : localAdapter;
    }

  });

  _exports.default = _default;
});